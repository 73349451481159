import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import Header from "./header";
import TableRow from "./rows";
import Pagination from "./pagination";
import styles from "./style.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import React from "react";
type TableProps = {
  data: JSX.Element[][];
  header: (string | any | JSX.Element)[];
  page?: number;
  pagesCount?: number;
  pageClicked?: (page: number) => void;
  withoutPagination?: boolean;
  bordered?: boolean;
  variant?: string;
  handleOnSort?: Function | null;
  onUpdatingRowOrder?: Function | null;
};

export default function SharedTable({
  data,
  header,
  page,
  pagesCount,
  pageClicked,
  withoutPagination,
  variant = "",
  bordered = true,
  handleOnSort,
  isLoading = false,
  emptyIcon = null,
  emptyTitle = null,
  emptyMessage = null,
  children = null,
  onUpdatingRowOrder = () => {},
  isSortable = false,
}: TableProps): JSX.Element {
  const [tableRows, setTablesRows] = useState([]);

  useEffect(() => {
    if (data?.length) {
      setTablesRows([...data]);
    }
  }, [data]);

  const onDragEnd = async ({ source, destination, draggableId, ...reset }) => {
    if (
      source?.index == null ||
      destination?.index == null ||
      source?.index == destination?.index
    ) {
      return;
    }
    onUpdatingRowOrder(source.index, destination?.index);
  };

  return (
    <>
      <Table
        size="lg"
        bordered={bordered}
        responsive={true}
        className={`align-middle ${styles.table}`}
        variant={variant}
      >
        <thead>
          <Header header={header} handleOnSort={handleOnSort} />
        </thead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`TABLE_DRAG`}>
            {(provided) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {!isLoading && !!children && children}
                {!children &&
                  !isLoading &&
                  tableRows?.map((record: any, index: number) => {
                    return (
                      <TableRow key={index} data={record} index={index} isDraggable={isSortable} />
                    );
                  })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>

      {!!isLoading && (
        <div className="my-5 d-flex align-items-center justify-content-center w-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!children && !tableRows?.length && !isLoading && (
        <Container className="h-100 d-flex align-items-center px-5">
          <Row className="w-100 align-items-center justify-content-center text-center">
            <Col lg={6} xs={8} className="my-3">
              {!!emptyIcon && <img src={emptyIcon} alt="no orders" />}
              {!!emptyTitle && <h2 className="mt-5">{emptyTitle}</h2>}
              {!!emptyMessage && <p className="text-muted">{emptyMessage}</p>}
            </Col>
          </Row>
        </Container>
      )}

      {!withoutPagination &&
        !!pagesCount &&
        !!page &&
        !!pageClicked &&
        pagesCount > 0 &&
        (!!children || !!tableRows?.length) && (
          <div
            className={`d-flex justify-content-end align-items-center mt-3 w-100 ${styles.pagination}`}
          >
            <Pagination page={page} pagesCount={pagesCount} pageClicked={pageClicked} />
          </div>
        )}
    </>
  );
}
