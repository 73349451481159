import "./styles.scss";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

/**
 * Input Date
 * @documentation https://hypeserver.github.io/react-date-range/#definedrange
 * @return {JSX.Element}
 */
const InputDate = ({ ...reset }) => {
  return (
    <div className={"calender-container"}>
      <DateRange {...reset} />
    </div>
  );
};

export default InputDate;
