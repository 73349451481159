import { RotateImage } from "../../core/helpers/style.helper";
import next from "../../assets/icons/next.svg";
import prev from "../../assets/icons/prev.svg";
import firstPage from "../../assets/new-icons/first_page.svg";
import lastPage from "../../assets/new-icons/last_page.svg";
import { Button, Form } from "react-bootstrap";
import React from "react";
import styles from "./style.module.scss";
import AutoCompleteSearch from "../GoogleMap/auto-complete-search";
import Autocomplete from "../input-field/autocomplete";
type props = {
  pagesCount: number;
  page: number;
  pageClicked: (page: number) => void;
};

type PageLinkProps = {
  pageNo: number;
  label?: string | number | JSX.Element;
  isDisabled: boolean;
  pageLinkClicked: (page: number) => void;
};

export default function Pagination({ pagesCount, page, pageClicked }: props): JSX.Element {
  const PageLink = ({
    pageNo,
    label = "",
    pageLinkClicked,
    isDisabled,
  }: PageLinkProps): JSX.Element => {
    return (
      <>
        <Button
          className={isDisabled ? styles.customDisable : ""}
          disabled={isDisabled}
          variant=""
          onClick={() => pageLinkClicked(pageNo)}
        >
          {label}
        </Button>
      </>
    );
  };

  return (
    <>
      <span className="text-muted fw-medium my-1">Page Number</span>
      <span className={styles.pageNumber}>
        <Form.Control
          key={JSON.stringify(page - 1)}
          as="select"
          custom
          name="pagination"
          value={+page}
          onChange={(selected) => {
            page = +selected.target.value;

            pageClicked(+selected.target.value);
          }}
          className="w-100 py-1 px-2 bg-white rounded"
          style={{borderColor: '#ccc'}}
        >
          <option value="" key=""></option>
          {Array.from(Array(pagesCount), (_, i) => ({
            id: i + 1,
            value: i + 1,
          }))?.map((item) => {
            return (
              <option value={item.id} key={item.value}>
                {item.value}
              </option>
            );
          })}
        </Form.Control>
      </span>
      <PageLink
        pageNo={page - 1}
        label={<img src={firstPage} alt="fist page" style={RotateImage()} />}
        pageLinkClicked={() => pageClicked(1)}
        isDisabled={page - 1 === 0}
      />
      <PageLink
        pageNo={page - 1}
        label={<img src={prev} alt="prev" style={RotateImage()} />}
        pageLinkClicked={() => pageClicked(page - 1)}
        isDisabled={page - 1 === 0}
      />
      <span className="mx-2">
        {page} - {pagesCount}
      </span>
      <PageLink
        pageNo={page + 1}
        label={<img src={next} alt="next" style={RotateImage()} />}
        pageLinkClicked={() => pageClicked(page + 1)}
        isDisabled={page + 1 < 1 || page + 1 > pagesCount}
      />
      <PageLink
        pageNo={page + 1}
        label={<img src={lastPage} alt="last page" style={RotateImage()} />}
        pageLinkClicked={() => pageClicked(pagesCount)}
        isDisabled={page + 1 < 1 || page + 1 > pagesCount}
      />
    </>
  );
}
