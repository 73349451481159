import sortUp from "../../assets/icons/sort-up.svg";
import sortDown from "../../assets/icons/sort-down.svg";
import sorting from "../../assets/icons/sorting.svg";
import { useEffect, useState } from "react";

const Sorting = ({ head, handleOnSort }) => {
  const handleOnStartSorting = () => {
    if (!handleOnSort) {
      return;
    }
    handleOnSort(head.value, `${head.value} asc`);
  };

  const handleOnSortingDown = () => {
    if (!handleOnSort) {
      return;
    }
    handleOnSort(head.value, `${head.value} desc`);
  };

  const handleOnResetSorting = () => {
    if (!handleOnSort) {
      return;
    }
    handleOnSort(head.value, "default");
  };

  return (
    <>
      {!head?.sortable && null}
      {!!head?.sortable && (
        <div className={`sorting ${head?.sorting || "sortable"}`}>
          <div className="sort">
            <img
              src={sorting}
              width={"20px"}
              alt="sort"
              title="Sorting"
              onClick={handleOnStartSorting}
            />
          </div>
          <div className="sort-up">
            <img
              src={sortUp}
              width={"20px"}
              alt="sort up"
              title="Sorting Asc"
              onClick={handleOnSortingDown}
            />
          </div>
          <div className="sort-down">
            <img
              src={sortDown}
              width={"20px"}
              alt="sort down"
              title="Sorting Desc"
              onClick={handleOnResetSorting}
            />
          </div>
        </div>
      )}
    </>
  );
};

type HeaderProps = {
  header: (string | any | JSX.Element)[];
  handleOnSort?: Function | any;
};

export default function Header({ header, handleOnSort }: HeaderProps): JSX.Element {
  return (
    <tr>
      {header.map((head, index) => (
        <th key={index} className={head?.sortable ? "td-sortable" : ""}>
          <span>
            {head?.title || head}
            <Sorting head={head} handleOnSort={handleOnSort} />
          </span>
        </th>
      ))}
    </tr>
  );
}
