import { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { UserMenu } from "../../../components/dropdown";
import menuLogo from "../../../assets/icons/menu.svg";
import styles from "./style.module.scss";
import { STORAGE_CONSTRAINT } from "../../../core/enums/storage";
import Storage from "../../../core/helpers/storage";
import {
  CHANNEL_EVENTS,
  EVENTS_LISTENER,
  ORDER_EVENTS,
  SUBSCRIPTION_EVENTS,
} from "../../../core/enums/event-bus";
import Echo from "laravel-echo";
import { toast } from "react-toastify";
import useEventBus from "../../../core/hooks/useEventBus";
import Notifications from "./notification";
import notificationAudio from "../../../assets/audio/notification.mp3";
import notificationAudioWave from "../../../assets/audio/notification.wav";
import notificationAudioAac from "../../../assets/audio/notification.aac";
import notificationAudioOgg from "../../../assets/audio/notification.ogg";

import subNotificationAudioOgg from "../../../assets/audio/sub_notification.ogg";
import subNotificationAudioMp3 from "../../../assets/audio/sub_notification.mp3";
import subNotificationAudioAac from "../../../assets/audio/sub_notification.aac";
import subNotificationAudioWav from "../../../assets/audio/sub_notification.wav";
import React from "react";
require("pusher-js");

export type DashboardWrapperProps = {
  children: JSX.Element;
  withoutHeader?: boolean;
  withOverlay?: boolean;
  fullOverlay?: boolean;
  title?: string;
  additionalMenu?: JSX.Element;
};

export const DashboardWrapper = ({
  children,
  withoutHeader,
  fullOverlay,
  title = "",
  additionalMenu,
}: DashboardWrapperProps) => {
  const eventBus = useEventBus<any>();
  const [newOrder, setNewOrder] = useState(null);
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [newSubscription, setNewSubscription] = useState(null);
  const [updatedSubscription, setUpdatedSubscription] = useState(null);
  const [isSocketReady, setIsSocketReady] = useState(false);
  const audioRef = useRef();
  const audioSubscriptionRef = useRef();

  useEffect(() => {
    const root: any = document.querySelector("body");
    root.classList.remove("overlay");
    if (fullOverlay) {
      root.classList.add("overlay");
    }
  }, [fullOverlay]);

  useEffect(() => {
    (async () => {
      const token = Storage.get(STORAGE_CONSTRAINT.TOKEN);
      if (!window["Echo"] && !!token) {
        window["Echo"] = new Echo({
          broadcaster: "pusher",
          key: process.env.REACT_APP_PUSHER_APP_KEY,
          cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
          wsHost: process.env.REACT_APP_WS_HOST,
          wsPort: 6001,
          forceTLS: true,
          authEndpoint: process.env.REACT_APP_WS_AUTH_ENDPOINT,
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        });
      }
      await setTimeout(() => {
        setIsSocketReady(!!window?.["Echo"]?.socketId());
      }, 1500);
    })();
  }, []);

  useEffect(() => {
    if (!!window?.["Echo"]?.socketId()) {
      window["Echo"].options.auth.headers["X-Socket-Id"] = window["Echo"].socketId();
    }

    window["Echo"]
      .private(CHANNEL_EVENTS.ORDERS)
      .listen(EVENTS_LISTENER.CREATED, (payload) => {
        setNewOrder(payload);
      })
      .listen(EVENTS_LISTENER.UPDATED, (payload) => {
        setUpdatedOrder(payload);
      });

    window["Echo"]
      .private(CHANNEL_EVENTS.SUBSCRIPTIONS)
      .listen(EVENTS_LISTENER.CREATED, (payload) => {
        setNewSubscription(payload);
      })
      .listen(EVENTS_LISTENER.UPDATED, (payload) => {
        setUpdatedSubscription(payload);
      });
  }, [isSocketReady]);

  useEffect(async () => {
    if (!!newOrder) {
      eventBus.publish({
        topic: ORDER_EVENTS.NEW_ORDER_CREATED,
        payload: newOrder,
      });
      setNewOrder(null);
      await playSound(audioRef);
    }

    if (!!updatedOrder) {
      eventBus.publish({
        topic: ORDER_EVENTS.ORDER_UPDATED,
        payload: updatedOrder,
      });
      setUpdatedOrder(null);
    }

    if (!!newSubscription) {
      eventBus.publish({
        topic: SUBSCRIPTION_EVENTS.NEW_SUBSCRIPTION_CREATED,
        payload: newSubscription,
      });
      setNewSubscription(null);
      await playSound(audioSubscriptionRef);
    }

    if (!!updatedSubscription) {
      eventBus.publish({
        topic: SUBSCRIPTION_EVENTS.SUBSCRIPTION_UPDATED,
        payload: updatedSubscription,
      });
      setUpdatedSubscription(null);
    }

    return () => {
      eventBus.publish({
        topic: ORDER_EVENTS.NEW_ORDER_CREATED,
        payload: null,
      });
      eventBus.publish({
        topic: ORDER_EVENTS.ORDER_UPDATED,
        payload: null,
      });

      eventBus.publish({
        topic: SUBSCRIPTION_EVENTS.NEW_SUBSCRIPTION_CREATED,
        payload: null,
      });

      eventBus.publish({
        topic: SUBSCRIPTION_EVENTS.SUBSCRIPTION_UPDATED,
        payload: null,
      });
      setNewOrder(null);
      setUpdatedOrder(null);
      setNewSubscription(null);
      setUpdatedSubscription(null);
      audioRef.current = null;
    };
  }, [newOrder, updatedOrder, newSubscription, updatedSubscription]);

  const playSound = async (ref) => {
    try {
      await ref.current.play();
    } catch (err) {
      console.log("Failed to play audio, error: " + err);
    }
  };

  useEffect(() => {
    prepareSideMenu(Storage.get(STORAGE_CONSTRAINT.MIN_SIDE_NAV));
    window.playSound = (id = 1) => {
      if (id === 1) {
        return playSound(audioRef);
      }
      return playSound(audioSubscriptionRef);
    };
  }, []);

  const handleOnToggleMenu = () => {
    const isSideMenuMin: boolean = !!document.querySelector("body.min-side-menu");
    prepareSideMenu(!isSideMenuMin);
  };

  const prepareSideMenu = (isSideMenuMin = false) => {
    const root: any = document.querySelector("body");

    if (isSideMenuMin) root.classList.add("min-side-menu");
    else root.classList.remove("min-side-menu");
    Storage.set(STORAGE_CONSTRAINT.MIN_SIDE_NAV, isSideMenuMin);
  };

  return (
    <>
      <div className={`${styles.wrapper} d-md-flex d-none`}>
        <div className={"overlay-container"} />
        <audio controls className={"d-none"} ref={audioRef}>
          <source src={notificationAudio} type="audio/mp3"></source>
          <source src={notificationAudioOgg} type="audio/ogg"></source>
          <source src={notificationAudioWave} type="audio/wav"></source>
          <source src={notificationAudioAac} type="audio/acc"></source>
        </audio>

        <audio controls className={"d-none"} ref={audioSubscriptionRef}>
          <source src={subNotificationAudioOgg} type="audio/ogg"></source>
          <source src={subNotificationAudioMp3} type="audio/mp3"></source>
          <source src={subNotificationAudioAac} type="audio/aac"></source>
          <source src={subNotificationAudioWav} type="audio/wav"></source>
        </audio>
        <div className={`${styles.wrapperWidth} d-flex flex-column main-container`}>
          {!withoutHeader && (
            <div className={`w-100 border-bottom d-flex align-items-center px-3 ${styles.header}`}>
              <div className={styles.headerContainer}>
                <div className={styles.headerTitle}>
                  <Button variant="" onClick={handleOnToggleMenu}>
                    <img src={menuLogo} alt="menu" />
                  </Button>
                  <h4 className="m-0 mx-3">
                    <strong>{title}</strong>
                  </h4>
                  {additionalMenu}
                </div>
                <div className={styles.headerAction}>
                  <Notifications />
                  <UserMenu />
                </div>
              </div>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
};
