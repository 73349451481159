import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules
const ListBranchesPage = React.lazy(() => import(`../pages/dashboard/seller/branches/list`));
const CreateBranchesPage = React.lazy(() => import(`../pages/dashboard/seller/branches/create`));
const EditBranchesPage = React.lazy(() => import(`../pages/dashboard/seller/branches/edit`));
// It's corresponding array routes
export const branchesRoutes: JSX.Element[] = [
  <Route
    exact
    key="seller-branches"
    path={DashboardRouteUtil.getSellerBranchesPath()}
    component={() => withAuth(withSuspense(ListBranchesPage))}
  />,
  <Route
    exact
    key="branches-create"
    path={DashboardRouteUtil.getSellerCreateBranchPath()}
    component={() => withAuth(withSuspense(CreateBranchesPage))}
  />,
  <Route
    exact
    key="branches-edit"
    path={DashboardRouteUtil.getSellerEditBranchPath()}
    component={() => withAuth(withSuspense(EditBranchesPage))}
  />,
];
