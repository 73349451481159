const dashboardPrefix = "/dashboard";

export class DashboardRouteUtil {
  public static getLoginPath(): string {
    return "/";
  }

  public static getForgetPassworPath(): string {
    return "/forget-password";
  }

  public static getSuccessSendPath(): string {
    return "/success-send";
  }

  public static getConfirmPasswordPath(): string {
    return "/password/reset";
  }

  public static getDashboardPath(): string {
    return `${dashboardPrefix}`;
  }

  public static getAccountSettingsPath(): string {
    return `${dashboardPrefix}/account-settings`;
  }

  public static getOrdersListPath(): string {
    return `${dashboardPrefix}/orders`;
  }

  public static getOrderEditPath(): string {
    return `${dashboardPrefix}/orders/:order_id/edit`;
  }

  public static getOrderEditUrl(order_id: string): string {
    return `${dashboardPrefix}/orders/${order_id}/edit`;
  }

  public static getSellersListPath(): string {
    return `${dashboardPrefix}/sellers`;
  }

  public static getFeaturedSellersListPath(): string {
    return `${dashboardPrefix}/sellers/featured`;
  }

  public static getSellerCreatePath(): string {
    return `${dashboardPrefix}/sellers/create`;
  }

  public static getSellerWorkingHoursPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/working-hours`;
  }

  public static getSellerWorkingHoursUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/working-hours`;
  }

  public static getSellerEditPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/edit`;
  }

  public static getSellerEditUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/edit`;
  }

  public static getSellerSectionPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/sections`;
  }

  public static getSellerSectionUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/sections`;
  }

  public static getSellerOfferPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/offers`;
  }

  public static getSellerOfferUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/offers`;
  }

  public static getSellerBranchesUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/branches`;
  }

  public static getSellerBranchesPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/branches`;
  }

  public static getSellerCreateBranchUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/branches/create`;
  }

  public static getSellerCreateBranchPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/branches/create`;
  }

  public static getSellerEditBranchUrl(seller_id: string, branch_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/branches/edit/${branch_id}`;
  }

  public static getSellerEditBranchPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/branches/edit/:branch_id`;
  }
  public static getSellerItemsPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/items`;
  }

  public static getSellerItemsUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/items`;
  }

  public static getSellerItemsListPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/items`;
  }

  public static getSellerItemsListUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/items`;
  }

  public static getSellerItemsCreatePath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/items/create`;
  }

  public static getSellerItemsCreateUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/items/create`;
  }

  // Note: Duplicate is the same like create bug we add the duplicate_id as a query param
  public static getSellerItemsDuplicateUrl(seller_id: any, duplicate_id: any): string {
    return `${dashboardPrefix}/sellers/${seller_id}/items/create?duplicate_id=${duplicate_id}`;
  }

  public static getSellerItemsEditPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/items/edit/:item_id`;
  }

  public static getSellerBranchItemsEditPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/branches/:branch_id/items/edit/:item_id`;
  }

  public static getSellerBranchItemsEditUrl(
    seller_id: string,
    branch_id: string,
    item_id: string
  ): string {
    return `${dashboardPrefix}/sellers/${seller_id}/branches/${branch_id}/items/edit/${item_id}`;
  }

  public static getSellerItemsEditUrl(seller_id: string, item_id: any): string {
    return `${dashboardPrefix}/sellers/${seller_id}/items/edit/${item_id}`;
  }

  public static getCategoriesPath(): string {
    return `${dashboardPrefix}/categories`;
  }

  public static getOffersPath(): string {
    return `${dashboardPrefix}/marketing/vouchers`;
  }

  public static getOffersCreatePath(): string {
    return `${dashboardPrefix}/marketing/vouchers/create`;
  }
  // Marketing Module Pathes Helpers
  public static getMarketingModulePath(): string {
    return `${dashboardPrefix}/marketing`;
  }

  public static getPromorionsCreatePath(): string {
    return `${dashboardPrefix}/marketing/promotions/create`;
  }

  public static getPromorionsEditPath(): string {
    return `${dashboardPrefix}/marketing/promotions/edit/:promotion_id`;
  }
  public static getPromorionsEditUrl(promotion_id: string): string {
    return `${dashboardPrefix}/marketing/promotions/edit/${promotion_id}`;
  }

  public static getCouponsCreatePath(): string {
    return `${dashboardPrefix}/marketing/coupons/create`;
  }

  public static getCouponsEditPath(): string {
    return `${dashboardPrefix}/marketing/coupons/edit/:coupon_id`;
  }
  public static getCouponsEditUrl(coupon_id: string): string {
    return `${dashboardPrefix}/marketing/coupons/edit/${coupon_id}`;
  }

  // Customers Feedback Module Pathes Helpers
  public static getListCustomersFeedbackPath(): string {
    return `${dashboardPrefix}/customers-feedback`;
  }

  // Customers Module Pathes Helpers
  public static getListCustomersPath(): string {
    return `${dashboardPrefix}/customers`;
  }

  public static getCustomerDetailsPath(): string {
    return `${dashboardPrefix}/customers/:customerId`;
  }

  public static setCustomerDetailsPath(customerId): string {
    return `${dashboardPrefix}/customers/${customerId}`;
  }

  // Audits Module Pathes Helpers
  public static getListAuditsPath(): string {
    return `${dashboardPrefix}/audits`;
  }

  public static getAuditDetailsPath(): string {
    return `${dashboardPrefix}/audits/:auditId`;
  }

  public static setAuditDetailsPath(auditId): string {
    return `${dashboardPrefix}/audits/${auditId}`;
  }

  // Permission Module
  public static getPermissionsModulePath(): string {
    return `${dashboardPrefix}/permissions`;
  }

  // Users Module
  public static getCreateUserModulePath(): string {
    return `${dashboardPrefix}/permissions/users/create`;
  }

  // CITIES & Countries (location management)

  public static getCitiesListPath(): string {
    return `${dashboardPrefix}/location-management/cities`;
  }

  public static getCountriesListPath(): string {
    return `${dashboardPrefix}/location-management/countries`;
  }

  public static getCitiesCreatePath(): string {
    return `${dashboardPrefix}/location-management/cities/create`;
  }

  public static getCountriesCreatePath(): string {
    return `${dashboardPrefix}/location-management/countries/create`;
  }

  public static getCitiesEditPath(): string {
    return `${dashboardPrefix}/location-management/cities/:city_id/edit`;
  }

  public static getCitiesEditUrl(city_id: string): string {
    return `${dashboardPrefix}/location-management/cities/${city_id}/edit`;
  }

  public static getCountriesEditPath(): string {
    return `${dashboardPrefix}/location-management/countries/:country_id/edit`;
  }

  public static getCountriesEditUrl(city_id: string): string {
    return `${dashboardPrefix}/location-management/countries/${city_id}/edit`;
  }
  // Subscriptions

  public static getSubscriptionsListPath(): string {
    return `${dashboardPrefix}/subscriptions`;
  }

  public static getSettingsListPath(): string {
    return `${dashboardPrefix}/settings`;
  }
  public static getSubscriptionsSettingsListPath(): string {
    return `${dashboardPrefix}/settings/duration`;
  }
  public static getSettingsAppVersionListPath(): string {
    return `${dashboardPrefix}/settings/app-version`;
  }

  public static getSettingsAppConfigurationsListPath(): string {
    return `${dashboardPrefix}/settings/configurations`;
  }
  public static getSettingsAppConfigurationsEditPath(): string {
    return `${dashboardPrefix}/settings/configurations/:id/edit`;
  }
  public static getSettingsAppConfigurationsEditPagePath(id): string {
    return `${dashboardPrefix}/settings/configurations/${id}/edit`;
  }
  public static getSettingsAppReferralConfigListPath(): string {
    return `${dashboardPrefix}/settings/referral-config`;
  }
  public static getSettingsAppVersionEditPath(): string {
    return `${dashboardPrefix}/settings/app-version/:appVersionId/edit`;
  }
  public static getSettingsAppVersionEditPagePath(id): string {
    return `${dashboardPrefix}/settings/app-version/${id}/edit`;
  }
  public static getSettingsAppVersionCreatePath(): string {
    return `${dashboardPrefix}/settings/app-version/create`;
  }
  public static getSubscriptionsCreatePath(): string {
    return `${dashboardPrefix}/subscriptions/create`;
  }

  public static getSubscriptionsEditPath(): string {
    return `${dashboardPrefix}/subscriptions/:subscriptionId/edit`;
  }

  public static getSubscriptionsEditUrl(subscriptionId: string | number): string {
    return `${dashboardPrefix}/subscriptions?id=${subscriptionId}`;
  }

  // seller balance
  public static getSellerBalancePath(): string {
    return `${dashboardPrefix}/seller-balance`;
  }
  // seller balance branches
  public static getSellerBalanceBranchesPath(): string {
    return `${dashboardPrefix}/seller-balance/branches`;
  }

  public static getSellerInvoiceAdjustmentPath(): string {
    return `${dashboardPrefix}/seller-balance/invoice-adjustment`;
  }
  public static getAnnouncementPath(): string {
    return `${dashboardPrefix}/announcements`;
  }
  public static editAnnouncement(announcement_id?: string): string {
    return announcement_id
      ? `${dashboardPrefix}/announcements/edit/${announcement_id}`
      : `${dashboardPrefix}/announcements/edit/:announcement_id`;
  }
  public static createAnnouncement(): string {
    return `${dashboardPrefix}/announcements/create`;
  }

  public static getBannerPath(): string {
    return `${dashboardPrefix}/banners`;
  }
  public static editBanner(announcement_id?: string): string {
    return announcement_id
      ? `${dashboardPrefix}/banners/edit/${announcement_id}`
      : `${dashboardPrefix}/banners/edit/:announcement_id`;
  }
  public static createBanner(): string {
    return `${dashboardPrefix}/banners/create`;
  }

  public static getNotificationPath(): string {
    return `${dashboardPrefix}/notifications`;
  }
  public static editNotification(notification_id?: string): string {
    return notification_id
      ? `${dashboardPrefix}/notifications/edit/${notification_id}`
      : `${dashboardPrefix}/notifications/edit/:notification_id`;
  }
  public static createNotification(): string {
    return `${dashboardPrefix}/notifications/create`;
  }
  public static getPaymentTransaction(): string {
    return `${dashboardPrefix}/payment-transaction`;
  }
  public static getPaymentTransactionDetailsPath(): string {
    return `${dashboardPrefix}/payment-transaction/:paymentId`;
  }

  public static setPaymentTransactionDetailsPath(paymentId): string {
    return `${dashboardPrefix}/payment-transaction/${paymentId}`;
  }
  public static getReportsCoupon(): string {
    return `${dashboardPrefix}/reports/coupon`;
  }
}
