import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import xIcon from "../../../assets/icons/close-light.svg";
import closeIcon from "../../../assets/icons/close.svg";
import docIcon from "../../../assets/icons/doc.png";
import uploadImgIcon from "../../../assets/icons/image-upload.svg";
import viewIcon from "../../../assets/icons/view.svg";
import {
  MAX_FILE_SIZE,
  SUPPORTED_FORMATS_APPLICATIONS,
  SUPPORTED_FORMATS_IMAGES,
} from "../../../core/constants/file.contants";
import Loader from "../../loader";
import "./styles.scss";

/**
 * Drag Drop Component
 * @return {JSX.Element}
 */
const DragDrop = ({
  label = "",
  image = "",
  error = "",
  onChange = null,
  accept = "image/*",
  isLoading = false,
  hint = "",
  tabIndex = 0,
  showDelete = false,
  required = false,
  inputClass = "",
  inputWrapperClasses = "",
}) => {
  const fileRef = React.useRef();
  const [file, setFile] = React.useState("");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [isImageType, setIsImageType] = React.useState(false);
  const [validationError, setValidationError] = React.useState("");
  const [acceptFile, setAcceptFile] = React.useState([]);
  const { t } = useTranslation();

  const setIsImageFile = (image) => {
    const ext = image?.split(".")?.pop();
    if (ext?.search("blob") < 0) {
      setIsImageType(SUPPORTED_FORMATS_IMAGES?.find((item) => item.search(ext) >= 0) || false);
    }
  };
  React.useEffect(() => {
    if (typeof image === "string" && !!image) {
      setIsImageFile(image);
      setFile(image);
    } else if (typeof image === "object" && !!image) {
      setIsImageFile(image?.path);
      setFile(image?.path || URL.createObjectURL(image));
    }
  }, [image]);

  React.useEffect(() => {
    handleFileAccept();
  }, [accept]);

  React.useEffect(() => {
    if (selectedImage) {
      return (document.body.style.overflow = "hidden");
    }
    document.body.style.overflow = null;
  }, [selectedImage]);

  const handleFileAccept = () => {
    let acceptList = [];
    const acceptArray = accept.split(",");

    if (acceptArray.includes("application/*")) {
      acceptList = [...SUPPORTED_FORMATS_APPLICATIONS];
    }

    if (acceptArray.includes("image/*") || !acceptList?.length) {
      acceptList = [...acceptList, ...SUPPORTED_FORMATS_IMAGES];
    }

    setAcceptFile(acceptList);
  };

  const handleOnRemoveFile = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidationError("");
    setFile(null);
  };
  /**
   * Handle on file Updated
   * @return {void}
   */
  const handleOnFileChanged = async (event) => {
    if (!onChange) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setValidationError("");
    if (!!event) {
      setFile(null);
    }
    if (!event?.target?.files[0]) {
      return;
    }

    const file = event?.target?.files[0];

    if (file.size > MAX_FILE_SIZE) {
      return setValidationError(t("form.errors.image.large"));
    }

    if (!acceptFile.includes(file.type)) {
      return setValidationError(t("form.errors.image.notImage"));
    }

    setIsImageType(SUPPORTED_FORMATS_IMAGES.includes(file.type));
    await onChange(file);
    setFile(URL.createObjectURL(file));
  };

  /**
   * Handle on drag file canceled
   * @return {void}
   */
  const handleOnDragFile = (element) => {
    handleOnFileChanged(element);
  };

  const handleOnPreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (file?.split(".")?.pop() !== "pdf" && !isImageType) {
      setSelectedImage(null);
      downloadFile(file, label);
      return;
    }

    return setSelectedImage(file);
  };

  /**
   * Handle on download file
   * @param file
   * @param filename
   */
  const downloadFile = (file, filename) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = filename;
    link.click();
  };

  return (
    <div className={`app-drag-drop ${inputClass}`}>
      {!!label && (
        <span className="input-label">
          {label} {required && <span className="required-star">*</span>}
        </span>
      )}

      <div
        key={`drag-drop-key-${tabIndex}`}
        tabIndex={tabIndex}
        className={`draggable ${inputWrapperClasses} ${!!file ? "file-uploaded" : ""}`}
        onDragOver={handleOnDragFile}
      >
        {!!isLoading && (
          <div className="loading-bar">
            <Loader
              className={"fetch-loading"}
              variant="primary"
              size={"lg"}
              animation={"border"}
            />
          </div>
        )}

        {!isLoading && onChange && (
          <input
            type="file"
            ref={fileRef}
            value={""}
            accept={accept}
            className="input-file-hidden"
            onChange={handleOnFileChanged}
          />
        )}
        {!!file && (
          <div className="drag-drop-content-file">
            {!!showDelete && (
              <div className="remove-container" onClick={handleOnRemoveFile}>
                <img src={xIcon} className={"remove-image"} />
              </div>
            )}
            <div className="hand-preview-box" onClick={handleOnPreview}>
              <img src={viewIcon} className={"hand-preview"} />
            </div>
            {!!isImageType && <img src={file} className={"image-clickable"} />}
            {!isImageType && <img src={docIcon} className={"image-clickable"} />}
          </div>
        )}

        {!file && !!onChange && (
          <div className="drag-drop-content-file">
            <img src={uploadImgIcon} />
          </div>
        )}

        {!!onChange && (
          <Button
            variant="outline-light text-muted d-block mx-auto mt-3"
            onClick={() => fileRef.current.click()}
          >
            Add file
          </Button>
        )}

        {!file && !!onChange && <span className={"upload-helper"}>or drop image to upload</span>}
      </div>

      {!!hint && <span className="input-hint">{hint ?? ""}</span>}
      {!!error && <span className="input-error-hint">{error ?? ""}</span>}
      {!!validationError && <span className="input-error-hint">{validationError ?? ""}</span>}
      {!!selectedImage && (
        <div className="image-preview">
          <div className="image-preview-header">
            <img
              src={closeIcon}
              className={"remove-image"}
              onClick={() => setSelectedImage(null)}
            />
          </div>
          <>
            {!!isImageType && <img src={file} alt="present page" className={"image-box"} />}

            {!isImageType && <iframe src={file} className={"iframe-box"} loading="lazy" />}
          </>
        </div>
      )}
    </div>
  );
};
export default DragDrop;
