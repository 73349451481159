import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { DashboardRouteUtil } from "../../../../../core/routes/dashboard.route";
import { SideMargin } from "../../../../../core/helpers/style.helper";
import { PromotionStatus } from "../../../../../core/enums/promotion-status.enum";
import { CouponListType } from "../../../../../core/types/coupon.type";
import { SellerStatus } from "../../../../../components/sellerStatus";
import { getPromotionStatusColor } from "../../../../../core/helpers/list.helper";
import SharedTable from "../../../../../components/table";
import { Variant } from "../../../../../core/enums/variants.enum";
import { PagesType } from "../../../../../core/types/pagination.type";
import styles from "./style.module.scss";

import noCouponsPlaceholder from "../../../../../assets/icons/no-coupons.svg";
import editIcon from "../../../../../assets/icons/edit.svg";
import { userAuthCheck } from "../../../../../core/helpers/auth.helpr";
import {
  getCouponType,
  getCoupons,
  getCouponsCounts,
} from "../../../../../core/services/coupons.service";
import SearchField from "../../../../../components/search-field";
import React from "react";
import { Autocomplete } from "../../../../../components";
const handleCurrencyDisplay = (type, discount) => {
  if (discount) {
    return type === 1 || type === 4 ? `${discount} %` : `${discount} SAR`;
  }
  return " ";
};
export default function CouponsList() {
  const [ListParams, setListParams] = useState({
    page: 1,
    searchTerm: '',
    filter: -1
  });
  const [counts, setCounts] = useState<{ total: number; status_label: string }[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [secondLoader, setSecondLoader] = useState(false);
  const [paginationsObj, setPaginationsObj] = useState<PagesType>();
  const [tableElements, setTableElements] = useState<JSX.Element[][]>([]);
  const [list, setList] = useState<CouponListType[]>([]);
  const [isFetchingCounponTypes, setIsFetchingCounponTypes] = useState(false);
  const [selectedCouponType, setSelectedCouponType] = useState<Array<any>>([]);
  const [couponTypes, setCouponTypes] = useState<Array<{ id: number; value: string }>>([]);
  const { t } = useTranslation();

  const setDisplayElemnts = useCallback((data: CouponListType[]) => {
    const elements = data.map((item: CouponListType) => {
      let start_at = new Date(item.start_at);
      let end_at = new Date(item.end_at);
      return [
        <a>{item.id}</a>,
        <a>{item.en_coupon}</a>,
        <>
          {item.total_uses}/{item.max_uses} used
        </>,
        <>{item.type_label}</>,
        <>{handleCurrencyDisplay(item.type, item.discount_value)}</>,
        <>{item.discount_max_value ?? "-"}</>,
        <>
          {format(start_at, "MM/dd/yyyy hh:mm:ss")} - {format(end_at, "MM/dd/yyyy hh:mm:ss")}
        </>,
        <>
          <SellerStatus
            text={item.status_label}
            color={getPromotionStatusColor(item.status_label)}
          />
        </>,
        <>{item.method_type_label}</>,
        <div className="mx-auto">
          <Link to={DashboardRouteUtil.getCouponsEditUrl(item.id + "")}>
            <img src={editIcon} alt="edit" />
          </Link>
        </div>,
      ];
    });

    setTableElements(elements);
  }, []);

  const handleFilterCoupons = (dataFilter: number) => {
    setListParams({
      ...ListParams,
      page: 1,
      filter: dataFilter
    });
  };

  const handleSearchChange = (searchValue: string) => {
    setListParams({
      ...ListParams,
      page: 1,
      searchTerm: searchValue
    });
  };

  const fetchData = useCallback(async () => {
    ListParams.searchTerm === "" && ListParams.filter === -1 ? setIsLoading(true) : setSecondLoader(true);
    const type = selectedCouponType?.flatMap((item) => item.id);

    try {
      const res: { data: CouponListType[]; meta: PagesType } = await getCoupons(ListParams.page, {
        ...(ListParams.filter !== -1 && { status: ListParams.filter }),
        ...(ListParams.searchTerm !== "" && { keyword: ListParams.searchTerm }),
        ...(type?.length !== 0 && { type }),
      });
      setDisplayElemnts(res.data);
      setPaginationsObj(res.meta);
      setList(res.data);
    } catch (error) {
      userAuthCheck(error);
    } finally {
      setIsLoading(false);
      setSecondLoader(false);
    }
  }, [ListParams.page, setDisplayElemnts, ListParams.filter, ListParams.searchTerm, selectedCouponType]);

  useEffect(() => {
    setIsFetchingCounponTypes(true);
    getCouponType().then((items) => {
      setCouponTypes(items.data?.filter((item) => item.id !== 3));
    });
    setIsFetchingCounponTypes(false);
  }, []);

  useEffect(() => {
    fetchData();
    return () => {
      setTableElements([]);
      setList([]);
    };
  }, [fetchData]);

  const fetchCount = useCallback(async () => {
    const counts = await getCouponsCounts();
    setCounts(counts?.data?.filter((item: { status: number | null }) => item.status));
  }, []);

  useEffect(() => {
    fetchCount();
  }, []);

  const header = [
    t("table.header.promotion.id"),
    t("table.header.promotion.name"),
    t("table.header.promotion.usage"),
    t("table.header.promotion.type"),
    t("table.header.promotion.value"),
    t("table.header.promotion.upto"),
    t("table.header.promotion.validity"),
    t("table.header.status"),
    t("method_type_label"),
    t("table.header.actions"),
  ];
  return (
    <Container className="h-100">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : list.length > 0 || ListParams.searchTerm !== "" || ListParams.filter !== -1 ? (
        <Container className="mt-5 px-5">
          <Row className="align-items-center justify-content-between">
            <Col lg={4} md={8} xs={12} className="mb-3"></Col>
            <Col lg={8} md={8} xs={12} className="mb-3">
              <div
                className={`${styles.statistics} d-flex flex-wrap align-items-center justify-content-end`}
              >
                <Link to={DashboardRouteUtil.getCouponsCreatePath()}>
                  <Button variant="primary" className="px-3 py-1" style={SideMargin("10px")}>
                    {t("pages.marketing.coupons.placeholder.call.to.action")}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="bg-white justify-content-center shadow rounded pb-3">
            <Row className="align-items-center justify-content-center">
              <Col lg={12} className="border-bottom">
                <Button
                  variant=""
                  onClick={() => handleFilterCoupons(-1)}
                  className={`rounded-0 ${styles.filterBtn} ${ListParams.filter === -1 && styles.active}`}
                >
                  {t("filter.sellers.all")}
                </Button>
                {counts &&
                  counts.map((item: any) => (
                    <Button
                      key={item.status}
                      variant=""
                      onClick={() => handleFilterCoupons(item.status)}
                      className={`rounded-0 d-inline-flex ${styles.filterBtn} ${
                        ListParams.filter === item.status && styles.active
                      }`}
                    >
                      {item.status_label}
                      <SellerStatus
                        text={item.total}
                        color={
                          item.status_label === PromotionStatus.ACTIVE
                            ? Variant.SUCCESS
                            : item.status_label === PromotionStatus.SCHEDULED
                            ? Variant.WARNING
                            : Variant.DANGER
                        }
                      />
                    </Button>
                  ))}
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Col lg={6} className="my-3 position-relative">
                <SearchField
                  placeholder="Search Coupons Name"
                  value={ListParams.searchTerm}
                  handleOnSearch={handleSearchChange}
                />
              </Col>
              <Col lg={6} className="my-3">
                <Autocomplete
                  key={JSON.stringify(selectedCouponType)}
                  closeOnSelect
                  list={couponTypes}
                  value={selectedCouponType}
                  id={"order_type"}
                  multiple={true}
                  keyValue={"id"}
                  keyName={`value`}
                  isLoading={isFetchingCounponTypes}
                  placeholder={t("Coupon Type")}
                  onSelectedChange={(selected) => {
                    setSelectedCouponType(selected);
                  }}
                  items={"couponTypes"}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Col lg={12} xs={8}>
                {secondLoader ? (
                  <div className="my-5 d-flex align-items-center justify-content-center w-100 h-100">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : tableElements.length > 0 && paginationsObj ? (
                  <SharedTable
                    header={header}
                    data={tableElements}
                    page={paginationsObj?.current_page}
                    pagesCount={Math.ceil(paginationsObj?.total / paginationsObj.per_page)}
                    pageClicked={(page: number) => setListParams({
                      ...ListParams,
                      page
                    })}
                  />
                ) : (
                  <div className="text-center py-5">
                    <img src={noCouponsPlaceholder} alt="No Promotions" />
                    <p className="text-muted mt-5">{t("pages.order.empty.filter")}</p>
                  </div>
                )}
              </Col>
            </Row>
          </Row>
        </Container>
      ) : (
        <Container className="h-100 d-flex align-items-center px-5">
          <Row className="w-100 align-items-center justify-content-center text-center">
            <Col lg={6} xs={8} className="my-3">
              <img src={noCouponsPlaceholder} alt="No Coupons" />
              <h2 className="mt-5">{t("pages.marketing.coupons.placeholder.title")}</h2>
              <p className="text-muted">{t("pages.marketing.coupons.placeholder.body")}</p>
              <Link to={DashboardRouteUtil.getCouponsCreatePath()}>
                <Button variant="primary" className="px-3 py-2">
                  {t("pages.marketing.coupons.placeholder.call.to.action")}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
}
