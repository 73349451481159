import React from "react";
import Chart from "react-apexcharts";
import styles from "./chart.module.scss";

const AppChart = ({ data, label = "", height = "374px", width = "100%", isLoading = false }) => {
  const [options, setOptions] = React.useState({});

  React.useEffect(() => {
    if (!data?.options) {
      return;
    }
    // data.options.chart.toolbar.export.csv.filename = label;
    // data.options.chart.toolbar.export.svg.filename = label;
    // data.options.chart.toolbar.export.png.filename = label;

    setOptions({ ...data.options });
  }, [data?.options]);

  return (
    <div className={`${styles.chart} ${isLoading ? "chart-fetching" : ""}`} dir={"ltr"}>
      {!!label && (
        <div className={styles.chartHeader}>
          <span className={"label"}>{label}</span>
        </div>
      )}

      <Chart
        options={options}
        series={data?.series || []}
        type={data?.type || "bar"}
        height={height}
        width={width}
      />
    </div>
  );
};

export default AppChart;
