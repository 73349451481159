import { toast } from "react-toastify";
import { DashboardRouteUtil } from "../routes/dashboard.route";
import Storage from "../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../../core/enums/storage";

export const userAuthCheck = (error: any) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem("userAuth");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = DashboardRouteUtil.getLoginPath();
  }
   else {
    toast.error(error?.response?.data?.message || "something went wrong!");
  }
};

/**
 * Save User Permissions
 * @param permissions
 * @returns {<void | any>}
 */
export const prepareUserPermissions = async (permissions) => {
  if (!permissions) {
    return null;
  }

  let groups = {};
  permissions.forEach((value) => {
    let item = value.split("_");

    let key;
    let able;

    able = item.splice(item.length - 1, 1);
    item = item.splice(1, item.length);
    key = item.join("/");

    if (groups[key]) able = [...groups[key], ...able];

    groups[key] = able;
  });

  // save session permissions on the local storage
  Storage.set(STORAGE_CONSTRAINT.USER_PERMISSIONS, groups);
};

export const handleErrorList = (error: any) => {
  const {
    data: { errors },
    status,
  } = error.response;
  if (status === 401) {
    return userAuthCheck(error);
  }
  const errorsKeys = Object.keys(errors);
  const flatAllErrors = errorsKeys.map((key) => errors[key]).flat();
  flatAllErrors.forEach((error) => toast.error(error));
};
