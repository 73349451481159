import { HttpMethods } from "../enums/http-methods.enum";
import { UserLoginType } from "../types/auth.type";
import { makeRequest, baseURL } from "./http.service";

export const login = async (data: UserLoginType) => {
  const res = await makeRequest({
    url: `${baseURL}/login`,
    method: HttpMethods.POST,
    data,
  });
  return res;
};

export const logout = async () => {
  const res = await makeRequest({
    url: `${baseURL}/logout`,
    method: HttpMethods.POST,
  });
  return res;
};

export const forgetPassword = async (email: string) => {
  const res = await makeRequest({
    url: `${baseURL}/password/email`,
    method: HttpMethods.POST,
    data: { email },
  });
  return res;
};

export const resetPassword = async (data: any) => {
  return await makeRequest({
    url: `${baseURL}/password/reset/email`,
    method: HttpMethods.POST,
    data,
  });
};

export const refreshToken = async () => {
  const refresh_token = localStorage.getItem("refreshToken");
  if (refresh_token) {
    const res = await makeRequest({
      url: `${baseURL}/refresh-token`,
      method: HttpMethods.POST,
      data: {
        refresh_token,
      },
    });
    return res;
  }
};
