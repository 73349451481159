import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules (Markting Module)
const ListAuditsPage = React.lazy(() => import(`../pages/dashboard/audit/list`));
// It's corresponding array routes
export const AuditsRoutes: JSX.Element[] = [
  <Route
    exact
    key="list-audits"
    path={DashboardRouteUtil.getListAuditsPath()}
    component={() => withAuth(withSuspense(ListAuditsPage))}
  />,
  <Route
    exact
    key="list-audit-details"
    path={DashboardRouteUtil.getAuditDetailsPath()}
    component={() => withAuth(withSuspense(ListAuditsPage))}
  />,
];
