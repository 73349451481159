import "@carbon/charts/styles.css";
import styles from "./stats.module.scss";

const StatsCard = ({ label, icon, hint, value, percentage, percentage_color }) => {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.titleBox}>
          <div className={styles.titleContainer}>
            {!!icon && (
              <div className={styles.icon}>
                <img className={styles.iconImg} src={icon.thumbnail} alt={label} />
              </div>
            )}

            <div className={styles.title}>{label}</div>
          </div>
          {!!percentage && (
            <div className={styles.percentage} style={{ color: percentage_color }}>
              {percentage}
            </div>
          )}
        </div>

        <div className={styles.hint}>{hint}</div>
      </div>

      <div className={styles.body}>{value}</div>
    </div>
  );
};

export default StatsCard;
