import React, { useRef } from "react";
import xIcon from "../../../assets/icons/close.svg";
import arrowIcon from "../../../assets/icons/open-arrow.svg";
import Loader from "../../loader";

import "./styles.scss";
// import lodash globally to decrease the import consumption

/***
 * Auto Complete Field
 * @param list
 * @param value
 * @param keyName
 * @param keyValue
 * @param onChange
 * @param onSelectedChange
 * @param placeholder
 * @param hint
 * @param error
 * @param label
 * @param multiple
 * @param id
 * @param disabled
 * @param isLoading
 * @param closeOnSelect
 * @param isAsterisk
 * @param maxWidth
 * @param items
 * @param canSelectAll
 * @return {JSX.Element}
 */
const Autocomplete = ({
  openToTop = false,
  list = [],
  value = [],
  keyName = "id",
  keyValue = "name",
  onChange = null,
  onSelectedChange = null,
  placeholder = "Search",
  hint = "",
  error = "",
  label = "",
  multiple = false,
  id = "AutoComplete_1",
  disabled = false,
  isLoading = false,
  closeOnSelect = false,
  isAsterisk = false,
  maxWidth = "100%",
  hidePlaceholder = false,
  items = "",
  canSelectAll = true,
  handlePagination,
  secondKeyName = "",
  canClearSelection = true,
}) => {
  const _ = window["_"];
  const [isReady, setIsReady] = React.useState(false);
  const [listTop, setListTop] = React.useState("5.5rem");
  const [isListActive, setIsListActive] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedItems, setSelectedItems] = React.useState(value ? [...value] : []);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const autocompleteRef = useRef(null);
  const labelRef = React.useRef();
  const inputRef = React.useRef();
  const body = document.querySelector("body");
  let timeout = 0;

  React.useEffect(() => {
    if (isReady) {
      onSelectedChange(selectedItems);
      updateListTopPosition();
    }
  }, [selectedItems]);

  React.useEffect(() => {
    if (isReady && !!list.length && !!searchValue) {
      handleOnOpenList();
    }
  }, [list]);

  React.useEffect(() => {
    timeout = setTimeout(() => {
      setIsReady(true);
    }, 500);

    document.addEventListener("click", handleClickOutsideList, true);
  }, []);

  React.useEffect(
    () => () => {
      document.removeEventListener("click", null, true);
      labelRef.current = null;
      inputRef.current = null;
      debouncedSearchValue(null);
      timeout = null;
      body.style.overflow = null;
    },
    []
  );

  const isAllSelected = () => {
    return selectedItems.length === 0;
  };

  /***
   * handle on select all items
   * @param e
   * @param item
   */
  const handleOnSelectAll = (e) => {
    e.preventDefault();

    if (!!closeOnSelect) {
      handleOnCloseList();
    }

    if (isAllSelected()) {
      return removeAllFromList();
    }

    return addAllToList();
  };

  const handleScroll = (e) => {
    if (
      handlePagination &&
      e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight <= 5 &&
      !isLoading
    ) {
      handlePagination();
    }
  };
  /***
   * handle on select item
   * @param e
   * @param item
   */
  const handleOnSelect = (e, item) => {
    e.preventDefault();
    if (!!closeOnSelect) {
      handleOnCloseList();
    }

    if (isItemSelected(item)) {
      return removeItemFromList(item);
    }

    if (inputRef.current) {
      inputRef.current.value = "";
    }

    return addItemToList(item);
  };

  /**
   * check if the item selected
   * @param item
   * @return {boolean}
   */
  const isItemSelected = (item) => {
    return value === item || findIndex(item) > -1;
  };

  /***
   * find index from the array
   * @param item
   * @return {number}
   */
  const findIndex = (item) => {
    if (!value) {
      return -1;
    }
    return value?.findIndex((vItem) => vItem?.[keyValue] === item?.[keyValue]);
  };

  /***
   * handle on remove item from the list
   * @param item
   * @return {void}
   */
  const removeItemFromList = (item) => {
    if (multiple) {
      inputRef?.current?.focus();
    }
    let newList = [...selectedItems];
    const index = findIndex(item);
    newList.splice(index, 1);
    setSelectedItems(newList);
  };

  const removeAllFromList = () => {
    if (multiple) {
      inputRef?.current?.focus();
    }
    setSelectedItems([]);
  };

  /**
   * handle on add item to list
   * @param item
   * @return {void}
   */
  const addItemToList = (item) => {
    if (multiple) {
      inputRef?.current?.focus();
      return setSelectedItems([...selectedItems, item]);
    }

    setSelectedItems([item]);
    handleOnCloseList();
  };

  const addAllToList = () => {
    if (multiple) {
      inputRef?.current?.focus();
      return setSelectedItems([]);
    }

    setSelectedItems([]);
    handleOnCloseList();
  };

  /**
   * handle on update the list top position
   * @return {void}
   */
  const updateListTopPosition = () => {
    timeout = setTimeout(() => {
      const element = document.querySelector(`#DropDownList-${id} .autocomplete`);
      const labelElement = document.querySelector(`#DropDownList-${id} .autocomplete-label`);
      let top = element?.offsetHeight || 41;

      if (!!labelElement) {
        top = (top + labelElement?.offsetHeight + 10) || 25;
      }
      setListTop(`${top}px`);
    }, 30);
  };

  /**
   * handle on auto complete click
   * @return {void}
   */
  const handleOnAutoCompleteClick = () => {
    inputRef?.current?.focus();
    handleOnOpenList();
  };

  /**
   * handle on open list
   * @return {void}
   */
  const handleOnOpenList = () => {
    setIsListActive(false);
    setActiveIndex(0);
    if (list?.length !== 0) {
      setIsListActive(true);
      updateListTopPosition();
    }
  };

  React.useEffect(() => {
    body.style.overflow = null;

    if (isListActive) {
      body.style.overflow = "hidden";
    }
  }, [isListActive]);

  /**
   * Handle on close list
   * @return {void}
   */
  const handleOnCloseList = () => {
    timeout = setTimeout(() => {
      setIsListActive(false);
    }, 100);
  };

  /**
   * Handle on click out side
   * @param event
   */
  const handleClickOutsideList = (event) => {
    if (!document.getElementById(`DropDownList-${id}`)?.contains(event.target)) {
      return handleOnCloseList();
    }
  };

  /**
   * handle on search
   * @param event
   */
  const handleOnSearch = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (onChange) {
      onChange(event?.target?.value);
    }
    setSearchValue(event?.target?.value);
  };

  /**
   * Search by debounce
   * @type {(function(): (*))|*|(function(): (*))}
   */
  const debouncedSearchValue = React.useCallback(window._.debounce(handleOnSearch, 1000), []);

  const handleCloseWhenClickInField = React.useCallback(() => {
    if (isListActive) handleOnCloseList();
  }, [isListActive]);

  const handleOnUp = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let newIndex = 0;

    if (!isListActive) {
      handleOnOpenList();
      setActiveIndex(0);
      return;
    }

    if (event.code === "Enter") {
      return handleOnSelect(event, list?.[activeIndex]);
    }

    if (event.code === "ArrowDown") {
      newIndex = 1 + activeIndex;
    }

    if (event.code === "ArrowUp" && activeIndex > 0) {
      newIndex = 1 - activeIndex;
    } else if (event.code === "ArrowUp" && activeIndex <= 0) {
      newIndex = list.length - 1;
    }

    if (activeIndex < 0) {
      newIndex = list.length - 1;
    }

    if (activeIndex >= list.length) {
      newIndex = 0;
    }

    setActiveIndex(Math.abs(newIndex));
  };

  React.useEffect(() => {
    const listElement = document.querySelector(`#DropDownList-${id} .list`);
    const itemElement = document.querySelector(
      `#list_item_${list?.[activeIndex]?.id}_${activeIndex}`
    );
    listElement?.scrollTo(0, itemElement?.offsetTop);
  }, [activeIndex]);

  return (
    <div
      className={`app-auto-complete ${!!isListActive ? "active-list" : ""} ${
        !!error ? "input-errors" : ""
      } ${!!disabled ? "input-disabled" : ""} ${!_.isEmpty(selectedItems) ? "is-selected" : ""} ${
        !!multiple ? "is-multiple" : ""
      }`}
      id={`DropDownList-${id}`}
      onKeyUp={handleOnUp}
      style={{ width: maxWidth, maxWidth }}
    >
      {!!label && (
        <p className="text-muted mb-1 autocomplete-label" ref={labelRef}>
          {label} {isAsterisk && <span className="asterisk-required">*</span>}
        </p>
      )}
      <div className={`autocomplete`} onClick={handleOnAutoCompleteClick}>
        <div className="selected-items ">
          {isAllSelected() && canSelectAll ? (
            <span className="selected-item" onClick={() => removeAllFromList()}>
              <span className="selected-text">All {items}</span>
              <img src={xIcon} alt="close" className="remove-me" />
            </span>
          ) : (
            selectedItems.map((item, index) => {
              return (
                index < 1 && (
                  <span
                    className="selected-item"
                    key={`selected-item-${index}`}
                    onClick={() => (canClearSelection ? removeItemFromList(item) : null)}
                  >
                    {secondKeyName ? (
                      <span className="selected-text">{`${item?.[keyName]} - ${item?.[secondKeyName]} `}</span>
                    ) : (
                      <span className="selected-text">{item?.[keyName]}</span>
                    )}
                    {canClearSelection && <img src={xIcon} alt="close" className="remove-me" />}
                  </span>
                )
              );
            })
          )}
          {selectedItems.length >= 2 && !isAllSelected() && (
            <span className="selected-item" onClick={() => setIsListActive(true)}>
              <span className="selected-text">{`+ ${selectedItems.length - 1} more`}</span>
            </span>
          )}
          <input
            type="text"
            placeholder={!hidePlaceholder ? placeholder : ""}
            className={"input-search"}
            ref={inputRef}
            onClick={handleCloseWhenClickInField}
            onChange={debouncedSearchValue}
          />
        </div>
        <>
          {!isLoading && (
            <img
              src={arrowIcon}
              onClick={handleCloseWhenClickInField}
              className={"drop-down-arrow"}
              alt="arrow"
              color={"#747474"}
            />
          )}

          {isLoading && (
            <Loader className={"fetch-loading"} variant="primary" size={"sm"} animation={"grow"} />
          )}
        </>
      </div>
      <div
        className="list"
        style={openToTop ? { bottom: listTop, top: "auto" } : { top: listTop }}
        onScroll={(e) => handleScroll(e)}
        ref={autocompleteRef}
      >
        {canSelectAll && (
          <div
            className={`list-item ${isAllSelected() ? "active" : ""} `}
            onClick={(e) => handleOnSelectAll(e)}
          >
            All {items}
          </div>
        )}
        {list?.map((item, index) => (
          <div
            className={`list-item ${isItemSelected(item) ? "active" : ""} ${
              activeIndex === index && multiple ? "item-hover" : ""
            }`}
            key={`list-item-${index}`}
            id={`list_item_${item.id}_${index}`}
            onClick={(e) => handleOnSelect(e, item)}
          >
            {secondKeyName ? `${item?.[keyName]} - ${item?.[secondKeyName]}` : item?.[keyName]}
          </div>
        ))}
      </div>

      {!!hint && <span className="input-hint">{hint}</span>}
      {!!error && <small className="text-danger">{error}</small>}
    </div>
  );
};

export default Autocomplete;
