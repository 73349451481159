import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules (Cities Module)
const SettingDurationPage = React.lazy(() => import(`../pages/dashboard/Settings/Durations`));
const SettingAppVersionPage = React.lazy(() => import(`../pages/dashboard/Settings/app-version`));
const SettingSystemConfigurationsPage = React.lazy(
  () => import(`../pages/dashboard/Settings/configurations`)
);
const SettingSystemReferralConfigPage = React.lazy(
  () => import(`../pages/dashboard/Settings/referral-config`)
);

export const SettingsRoutes: JSX.Element[] = [
  <Route
    exact
    key="Duration"
    path={DashboardRouteUtil.getSubscriptionsSettingsListPath()}
    component={() => withAuth(withSuspense(SettingDurationPage))}
  />,
  <Route
    exact
    key="App-Version"
    path={DashboardRouteUtil.getSettingsAppVersionListPath()}
    component={() => withAuth(withSuspense(SettingAppVersionPage))}
  />,
  <Route
    exact
    key="App-Version-Edit"
    location={DashboardRouteUtil.getSettingsAppVersionEditPath()}
    path={DashboardRouteUtil.getSettingsAppVersionEditPath()}
    component={() => withAuth(withSuspense(SettingAppVersionPage))}
  />,
  <Route
    exact
    key="App-Version-Create"
    path={DashboardRouteUtil.getSettingsAppVersionCreatePath()}
    component={() => withAuth(withSuspense(SettingAppVersionPage))}
  />,

  <Route
    exact
    key="System-Configurations"
    path={DashboardRouteUtil.getSettingsAppConfigurationsListPath()}
    component={() => withAuth(withSuspense(SettingSystemConfigurationsPage))}
  />,
  <Route
    exact
    key="System-Configurations-Edit"
    path={DashboardRouteUtil.getSettingsAppConfigurationsEditPath()}
    component={() => withAuth(withSuspense(SettingSystemConfigurationsPage))}
  />,

  <Route
    exact
    key="System-Referral-Configurations"
    path={DashboardRouteUtil.getSettingsAppReferralConfigListPath()}
    component={() => withAuth(withSuspense(SettingSystemReferralConfigPage))}
  />,
];
