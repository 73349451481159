import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

const BannerPage = React.lazy(() => import("../pages/dashboard/banner/list"));

const CreateBannerPage = React.lazy(() => import("../pages/dashboard/banner/create"));

const EditBannerPage = React.lazy(() => import("../pages/dashboard/banner/edit"));

export const bannersRoutes: JSX.Element[] = [
  <Route
    exact
    key="banners"
    path={DashboardRouteUtil.getBannerPath()}
    component={() => withAuth(withSuspense(BannerPage))}
  />,
  <Route
    exact
    key="create-banner"
    path={DashboardRouteUtil.createBanner()}
    component={() => withAuth(withSuspense(CreateBannerPage))}
  />,
  <Route
    exact
    key="edit-banner"
    path={DashboardRouteUtil.editBanner()}
    component={() => withAuth(withSuspense(EditBannerPage))}
  />,
];
