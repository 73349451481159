import classes from "./style.module.scss";
import BellIcon from "../../../../assets/icons/bell.png";
import { useEffect, useState } from "react";
import { ORDER_EVENTS, SUBSCRIPTION_EVENTS } from "../../../../core/enums/event-bus";
import useEventBus from "../../../../core/hooks/useEventBus";
import { DashboardRouteUtil } from "../../../../core/routes/dashboard.route";
import { useHistory } from "react-router-dom";
import Storage from "../../../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../../../../core/enums/storage";

const Notification = () => {
  const [list, setList] = useState([]);

  const [count, setCount] = useState(0);
  const [toggleClass, setToggleClass] = useState(false);
  const eventBus = useEventBus<any>();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      initNotifications();
    }, 1000);
  }, []);

  const initNotifications = () => {
    const newList =
      window._.uniqBy(Storage.get(STORAGE_CONSTRAINT.NOTIFICATIONS_LIST) || [], "key") || [];
    setList(newList);
  };

  useEffect(() => {
    const newOrderListener = eventBus.subscribe(
      ORDER_EVENTS.NEW_ORDER_CREATED,
      handleOnCreateNewOrder
    );
    const orderUpdatingListener = eventBus.subscribe(
      ORDER_EVENTS.ORDER_UPDATED,
      handleOnUpdateNewOrder
    );

    const subscriptionCreatingListener = eventBus.subscribe(
      SUBSCRIPTION_EVENTS.NEW_SUBSCRIPTION_CREATED,
      handleOnCreateSubscription
    );
    const subscriptionUpdatingListener = eventBus.subscribe(
      SUBSCRIPTION_EVENTS.SUBSCRIPTION_UPDATED,
      handleOnUpdateSubscription
    );

    const count = list.filter((item) => !item.isRead).length;
    setCount(!!toggleClass ? 0 : count >= 100 ? "+99" : count);

    return () => {
      newOrderListener.unsubscribe();
      orderUpdatingListener.unsubscribe();
      subscriptionCreatingListener.unsubscribe();
      subscriptionUpdatingListener.unsubscribe();
    };
  }, [list]);

  useEffect(() => {
    if (!!toggleClass && list?.length) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = null;
      resetReadList();
    }

    document.addEventListener("click", handleClickOutsideList, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideList, true);
    };
  }, [toggleClass]);

  useEffect(() => {
    let title = "Mazaj Dashboard";
    if (count) {
      title = `(${count > 99 ? "+99" : count}) ${title}`;
    }
    document.getElementsByTagName("title")[0].innerHTML = title;
  }, [count]);

  // in case create order
  const handleOnCreateNewOrder = (data) => {
    if (!data) {
      return;
    }

    const now = window.moment(data.updated_at).format("hh:mm:ss A");
    updateList({
      title: `New order created, Order number is <b>${
        data?.order_number
      }</b> for customer name <b>${data?.customer?.name?.substring(0, 30)}</b> at ${now}`,
      path: DashboardRouteUtil.getOrderEditUrl(`${data.id}`),
      key: `CREATE_ORDER_${data?.order_number}`,
    });
  };
  // in case update order
  const handleOnUpdateNewOrder = (data) => {
    if (!data) {
      return;
    }

    const now = window.moment(data.updated_at).format("hh:mm:ss A");
    updateList({
      title: `Order updated, Order number is <b>${
        data?.order_number
      }</b> for customer name <b>${data?.customer?.name?.substring(0, 30)}</b> at ${now}`,
      path: DashboardRouteUtil.getOrderEditUrl(`${data.id}`),
      key: `UPDATED_ORDER_${data?.order_number}`,
    });
  };
  // in case create Subscription
  const handleOnCreateSubscription = (data) => {
    if (!data) {
      return;
    }

    const now = window.moment(data.updated_at).format("hh:mm:ss A");
    updateList({
      title: `Subscription created, Subscription number is <b>${
        data?.order_number
      }</b> for customer name <b>${data?.customer?.name?.substring(0, 30)}</b> at ${now}`,
      path: DashboardRouteUtil.getSubscriptionsEditUrl(`${data.id}`),
      key: `CREATE_SUBSCRIPTION_${data?.order_number}`,
    });
  };
  // in case update Subscription
  const handleOnUpdateSubscription = (data) => {
    if (!data) {
      return;
    }

    const now = window.moment(data?.updated_at).format("hh:mm:ss A");
    updateList({
      title: `Subscription updated, Order number is <b>${
        data?.order_number
      }</b> for customer name <b>${data?.customer?.name?.substring(0, 30)}</b> at ${now}`,
      path: DashboardRouteUtil.getSubscriptionsEditUrl(`${data.id}`),
      key: `UPDATED_SUBSCRIPTION_${data?.order_number}`,
    });
  };

  // update the list
  const updateList = (item) => {
    let newList = [...list];
    newList.unshift({
      ...item,
      isRead: false,
      date: window.moment.now(),
    });

    newList = window._.uniqBy(newList || [], "key") || [];
    if (!window._.isEmpty(newList)) {
      Storage.set(STORAGE_CONSTRAINT.NOTIFICATIONS_LIST, newList);
    }

    setList(newList);
  };

  // handle on toggle the list open/close
  const handleOnToggleList = () => {
    setToggleClass(!toggleClass);
  };

  // handle on click on the item
  const handleOnItemClicked = (item) => {
    document.getElementsByTagName("body")[0].style.overflow = null;
    history.push(item.path);
  };

  // handle once try to skip the list
  const handleClickOutsideList = (event) => {
    if (!!toggleClass && !document.getElementById("AppNotificationList")?.contains(event.target)) {
      return setToggleClass(false);
    }
  };

  const resetReadList = () => {
    const newList = list.map((item) => ({
      ...item,
      isRead: true,
    }));
    setList(newList);
    if (!window._.isEmpty(newList)) {
      Storage.set(STORAGE_CONSTRAINT.NOTIFICATIONS_LIST, newList);
    }
  };

  return (
    <div className={classes.notifications} id="AppNotificationList">
      <div className={classes.icon} onClick={handleOnToggleList}>
        {!!count && <span className={classes.count}>{count}</span>}
        <img src={BellIcon} alt="Icon" />
      </div>

      <div className={`${classes.notificationList} ${toggleClass ? classes.showList : ""}`}>
        {list.map((item, index) => (
          <div
            className={`${classes.notificationItem} ${!item.isRead ? classes.unread : ""}`}
            key={`notification-item-${index}`}
            onClick={() => handleOnItemClicked(item)}
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        ))}
      </div>
    </div>
  );
};
export default Notification;
