export const SUPPORTED_FORMATS_IMAGES = ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp"];
export const SUPPORTED_FORMATS_APPLICATIONS = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/xlsx",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

export const MAX_FILE_SIZE = 2 * 1024 * 1024;
