import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

const AnnouncementPage = React.lazy(() => import("../pages/dashboard/announcement/list"));

const CreateAnnouncementPage = React.lazy(() => import("../pages/dashboard/announcement/create"));

const EditAnnouncementPage = React.lazy(() => import("../pages/dashboard/announcement/edit"));

export const annoucemenetRoutes: JSX.Element[] = [
  <Route
    exact
    key="announcements"
    path={DashboardRouteUtil.getAnnouncementPath()}
    component={() => withAuth(withSuspense(AnnouncementPage))}
  />,
  <Route
    exact
    key="create-announcement"
    path={DashboardRouteUtil.createAnnouncement()}
    component={() => withAuth(withSuspense(CreateAnnouncementPage))}
  />,
  <Route
    exact
    key="edit-announcement"
    path={DashboardRouteUtil.editAnnouncement()}
    component={() => withAuth(withSuspense(EditAnnouncementPage))}
  />,
];
