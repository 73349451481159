import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DashboardRouteUtil } from "../../../core/routes/dashboard.route";
import { AppWrapper } from "../app";
import Storage from "../../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../../../core/enums/storage";

export type DashboardWrapperProps = {
  children: JSX.Element;
};

export const AuthWrapper = ({ children }: DashboardWrapperProps) => {
  const history = useHistory();

  useEffect(() => {
    const user = Storage.get(STORAGE_CONSTRAINT.USER_AUTH);

    if (!user || !user?.access_token) {
      history.push(DashboardRouteUtil.getLoginPath());
    }
  }, [history]);

  return <AppWrapper>{children}</AppWrapper>;
};
