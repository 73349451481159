import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import {
  OppositeSidePosition,
  SideMargin,
  SidePadding,
  SidePosition,
} from "../../../../core/helpers/style.helper";
import { DashboardRouteUtil } from "../../../../core/routes/dashboard.route";
import { DateRangePicker } from "../../../../components/datepicker";
import SharedTable from "../../../../components/table";
import noVouchersPlaceholder from "../../../../assets/icons/no-vouchers-placeholder.svg";
import searchIcon from "../../../../assets/icons/search.svg";
import exportIcon from "../../../../assets/icons/export.svg";
import closeIcon from "../../../../assets/icons/close.svg";
import styles from "./style.module.scss";
import { PagesType } from "../../../../core/types/pagination.type";
import { userAuthCheck } from "../../../../core/helpers/auth.helpr";
import { getVouchers, getVoucherStatuses } from "../../../../core/services/vouchers.service";
import { VoucherType } from "../../../../core/types/vouchers.type";
import { getFormattedPrice } from "../../../../core/helpers/price.helper";
import { Autocomplete } from "../../../../components";
import { toast } from "react-toastify";
import SearchField from "../../../../components/search-field";

export default function VouchersListPage() {
  const [listingParams, setListingParams] = useState({
    page: 1,
    searchTerm: ""
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showExport, setShowExport] = useState(false);
  const [paginationsObj, setPaginationsObj] = useState<PagesType>();
  const [showCustomDates, setShowCustomDates] = useState(false);
  const [tableElements, setTableElements] = useState<JSX.Element[][]>([]);
  const [list, setList] = useState<VoucherType[]>([]);

  const [isInitState, setIsInitState] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isStatusesFetching, setIsStatusesFetching] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    getStatuses();
  }, []);

  const setDisplayElemnts = useCallback((data: VoucherType[]) => {
    const elements = data.map((item: VoucherType) => {
      let start_at = new Date(item.start_at);
      let end_at = new Date(item.end_at);
      return [
        <Link to="#">{item.id}</Link>,
        <>{item.total_item}</>,
        <>
          {item.used_items_count}/{item.total_item} used
        </>,
        <>{getFormattedPrice(item.amount.toString())}</>,
        <>
          {format(start_at, "MM/dd/yyyy hh:mm:ss")} - {format(end_at, "MM/dd/yyyy hh:mm:ss")}
        </>,
        <>{item.validity_label}</>,
      ];
    });

    setTableElements(elements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (searchValue: string) => {
    setListingParams({
      page: 1,
      searchTerm: searchValue
    });
  };

  const handleDateChange = (value: string) => {
    if (value === "Custom Range") {
      setShowCustomDates(true);
    } else {
      setShowCustomDates(false);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const status = selectedStatus?.[0]?.id || null;
    try {
      const res: { data: VoucherType[]; meta: PagesType } = await getVouchers(listingParams.page, {
        ...(status ? { status } : null),
        ...(listingParams.searchTerm !== '' && { code: listingParams.searchTerm })
      });

      setDisplayElemnts(res.data);
      setPaginationsObj(res.meta);
      setList(res.data);
    } catch (error) {
      userAuthCheck(error);
    } finally {
      setIsLoading(false);
    }
  }, [listingParams.page, setDisplayElemnts, selectedStatus, listingParams.searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setIsInitState(!isLoading && _.isEmpty(selectedStatus) && _.isEmpty(list));
  }, [list, selectedStatus, isLoading]);

  const header = ["#", "Number of voucher", "Usage", "Amount", "Validity", "Credit expiry"];

  const getStatuses = async () => {
    setIsStatusesFetching(true);
    try {
      const response = await getVoucherStatuses();
      setStatuses(response);
      setIsStatusesFetching(false);
    } catch (error) {
      toast.error(error?.message || error);
      userAuthCheck(error);
      setIsStatusesFetching(false);
    }
  };

  return (
    <>
      <Container className="mt-5 px-5">
        <Row className="align-items-center justify-content-between">
          <Col lg={4} md={8} xs={12} className="mb-3">
            <Autocomplete
              closeOnSelect
              list={statuses}
              value={selectedStatus}
              keyValue={"id"}
              keyName={"value"}
              isLoading={isStatusesFetching}
              id={"STATUS"}
              placeholder={"Select Voucher Status"}
              onSelectedChange={setSelectedStatus}
            />
          </Col>
          <Col lg={8} md={8} xs={12} className="mb-3">
            <div
              className={`${styles.statistics} d-flex flex-wrap align-items-center justify-content-end`}
            >
              <Button
                variant=""
                className="py-1 btn-normal d-flex align-items-center"
                onClick={() => setShowExport(true)}
              >
                <img src={exportIcon} alt="export" />
                <span className="px-1">{t("actions.export")}</span>
              </Button>
              <Link to={DashboardRouteUtil.getOffersCreatePath()}>
                <Button variant="primary" className="px-3 py-1" style={SideMargin("10px")}>
                  Create new vouchers
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row className="bg-white justify-content-center shadow rounded pb-3">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="my-3 position-relative">
              <SearchField
                placeholder="Search Vouchers"
                value={listingParams.searchTerm}
                handleOnSearch={handleSearchChange}
              />
            </Col>
          </Row>
          {!isInitState && (
            <Row className="align-items-center justify-content-center">
              <Col lg={12} xs={8}>
                <SharedTable
                  header={header}
                  isLoading={isLoading}
                  data={tableElements}
                  page={paginationsObj?.current_page || 1}
                  emptyTitle={t("pages.order.empty.filter")}
                  emptyIcon={noVouchersPlaceholder}
                  pagesCount={Math.ceil(paginationsObj?.total / paginationsObj?.per_page)}
                  pageClicked={(page: number) => setListingParams({ ...listingParams, page})}
                />
              </Col>
            </Row>
          )}

          {!!isInitState && (
            <Row className="w-100 align-items-center justify-content-center text-center">
              <Col lg={6} xs={8} className="my-3">
                <img src={noVouchersPlaceholder} alt="no sellers" />
                <h2 className="mt-5">Manage Vouchers</h2>
                <p className="text-muted">
                  Create discount codes and automatic discounts that apply at checkout. You can also
                  combine discounts with compare at prices.
                </p>
                <Link to={DashboardRouteUtil.getOffersCreatePath()}>
                  <Button variant="primary" className="px-3 py-2">
                    Create new vouchers
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        </Row>
      </Container>
      {showExport && (
        <div className={styles.exportOption} style={OppositeSidePosition("0")}>
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col lg={12} xs={12} className="mt-3 d-flex align-items-center">
                <Button variant="" onClick={() => setShowExport(false)}>
                  <img src={closeIcon} alt="x" />
                </Button>
                <p className="m-0">Export Vouchers</p>
              </Col>
              <Col lg={12} xs={12} className="my-3">
                <Form.Control
                  className="w-100 p-2 bg-light"
                  as="select"
                  custom
                  onChange={(e) => handleDateChange(e.target.value)}
                >
                  <option value={t("form.select.options.today") as string}>
                    {t("form.select.options.today")}
                  </option>
                  <option value={t("form.select.options.yesterday") as string}>
                    {t("form.select.options.yesterday")}
                  </option>
                  <option value={t("form.select.options.last_7_days") as string}>
                    {t("form.select.options.last_7_days")}
                  </option>
                  <option value={t("form.select.options.last_30_days") as string}>
                    {t("form.select.options.last_30_days")}
                  </option>
                  <option value={t("form.select.options.last_90_days") as string}>
                    {t("form.select.options.last_90_days")}
                  </option>
                  <option value={t("form.select.options.this_year") as string}>
                    {t("form.select.options.this_year")}
                  </option>
                  <option value={t("form.select.options.last_year") as string}>
                    {t("form.select.options.last_year")}
                  </option>
                  <option value={t("form.select.options.custom_range") as string}>
                    {t("form.select.options.custom_range")}
                  </option>
                </Form.Control>
              </Col>
              <Col lg={12} xs={12} className="my-3">
                <DateRangePicker showCustomDates={showCustomDates} />
              </Col>
              <Col lg={12} xs={12} className="d-flex align-items-center justify-content-between">
                <Button variant="" size="sm" className="text-primary">
                  <strong>{t("actions.reset")}</strong>
                </Button>
                <Button variant="primary">
                  <strong>{t("actions.download")}</strong>
                </Button>
              </Col>
              <Col lg={12} xs={12} className="my-3"></Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}
