import React from "react";
import { Route } from "react-router-dom";
import { withSuspense } from "../core/helpers/withSuspense";
import { withAuth } from "../core/helpers/withAuth";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

const NotificationPage = React.lazy(() => import("../pages/dashboard/notification/list"));

const CreateNotificationPage = React.lazy(() => import("../pages/dashboard/notification/create"));

const EditNotificationPage = React.lazy(() => import("../pages/dashboard/notification/edit"));

export const notificationRoutes: JSX.Element[] = [
  <Route
    exact
    key="notifications"
    path={DashboardRouteUtil.getNotificationPath()}
    component={() => withAuth(withSuspense(NotificationPage))}
  />,
  <Route
    exact
    key="create-notification"
    path={DashboardRouteUtil.createNotification()}
    component={() => withAuth(withSuspense(CreateNotificationPage))}
  />,
  <Route
    exact
    key="edit-notification"
    path={DashboardRouteUtil.editNotification()}
    component={() => withAuth(withSuspense(EditNotificationPage))}
  />,
];
