import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
import React from "react";
import { Can } from "../../components/can";

export type BorderTabsProps = {
  Tabs: any[];
  activeTab: string;
  onChangeTab: Function;
  primary: boolean;
  disabled: boolean;
};


const TabButton = ({tab, onChange, disabled, idx, primary, activeTab})=> {

  const tabTitle = tab?.title || tab;

  return (
    <Button
      onClick={() => {
        if (!disabled) onChange(tabTitle, idx);
      }}
      key={idx}
      id={tabTitle === "Main seller" ? "MainSellerBtn" : ""}
      variant=""
      className={`rounded-0 ${primary ? styles.filterBtn : styles.subFilterBtn} ${
        tabTitle === activeTab ? (primary ? styles.active : styles.subActive) : ""
      }`}
    >
      {tabTitle}
    </Button>
  )
}

export default function BorderTabs({
  Tabs,
  activeTab,
  onChangeTab,
  primary,
  disabled,
}: BorderTabsProps) {

  const TabsELements: JSX.Element[] = Tabs.map((tab, idx) => {
    if(!tab?.permission){
      return <TabButton tab={tab} key={`tab-${idx}`} onChange={onChangeTab} disabled={disabled} idx={idx} primary={primary} activeTab={activeTab} />
    }

    return (
        <Can do='view' on={tab?.permission} key={`tab-${idx}`}>
          <TabButton tab={tab} onChange={onChangeTab} disabled={disabled} idx={idx} primary={primary} activeTab={activeTab} />
        </Can>
    )

  });

  return (
    <div className="border-bottom">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg={12}>{TabsELements}</Col>
        </Row>
      </Container>
    </div>
  );
}
