import styles from "./stats.module.scss";

const StatsCardFetching = () => {
  return (
    <div className={styles.cardFetching}>
      {[0, 1, 2, 3, 4].map((i) => (
        <div className={`${styles.card}`} key={`s-card-${i}`}>
          <div className={styles.header}>
            <div className={styles.titleBox}>
              <div className={styles.icon}></div>
              <div className={styles.title}></div>
            </div>
            <div className={styles.hint}></div>
          </div>
          <div className={styles.body}></div>
        </div>
      ))}
    </div>
  );
};

export default StatsCardFetching;
