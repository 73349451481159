import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

const SellerBalanceModule = React.lazy(() => import("../pages/dashboard/seller-balance"));
const SellerInvoiceAdjustmentModule = React.lazy(() => import("../pages/dashboard/seller-balance/invoice-adjustment/list"));

export const SellerBalance: JSX.Element[] = [
  <Route
    exact
    key="seller-balance-module"
    path={DashboardRouteUtil.getSellerBalancePath()}
    component={() => withAuth(withSuspense(SellerBalanceModule))}
  />,

  <Route
    exact
    key="seller-balance-branches-module"
    path={DashboardRouteUtil.getSellerBalanceBranchesPath()}
    component={() => withAuth(withSuspense(SellerBalanceModule))}
  />,

  <Route
    exact
    key="seller-invoice-adjustment-module"
    path={DashboardRouteUtil.getSellerInvoiceAdjustmentPath()}
    component={() => withAuth(withSuspense(SellerBalanceModule))}
  />,
];
