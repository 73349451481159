import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules (Cities Module)
const SubscriptionsListPage = React.lazy(() => import(`../pages/dashboard/subscription/list`));

export const SubscriptionsRoutes: JSX.Element[] = [
  <Route
    exact
    key="Subscription"
    path={DashboardRouteUtil.getSubscriptionsListPath()}
    component={() => withAuth(withSuspense(SubscriptionsListPage))}
  />,
];
