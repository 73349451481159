import { HttpMethods } from "../enums/http-methods.enum";
import { VoucherCreateType } from "../types/vouchers.type";
import { makeRequest, baseURL } from "./http.service";

export const getVouchers = async (page?: number, params?: any) => {
  return await makeRequest({
    url: `${baseURL}/vouchers`,
    method: HttpMethods.GET,
    params: { page, ...params },
  });
};

export const createVoucher = async (data: VoucherCreateType) => {
  return await makeRequest({
    url: `${baseURL}/vouchers`,
    method: HttpMethods.POST,
    data,
  });
};

export const getVoucherStatuses = async () => {
  return await makeRequest({
    url: `${baseURL}/vouchers/lists/status`,
    method: HttpMethods.GET,
  });
};
