import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getUserProfile } from "../../../core/services/account-settings.service";

export type DashboardWrapperProps = {
  permission: string;
  children: JSX.Element;
};

export const PermissionWrapper = ({ permission, children }: DashboardWrapperProps) => {
  const history = useHistory();

  const getProfileData = useCallback(async () => {
    try {
      const res = await getUserProfile();
      const permissions = res.permissions;
      if (!permissions.includes(permission)) {
        history.push("/dashboard/sellers");
      }
    } catch (e: any) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getProfileData();
  }, []);

  return <>{children}</>;
};
