import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../core/helpers/date.helper";
import styles from "./styles.scss";
import React from "react";
type DateRangePickerProps = {
  showCustomDates?: boolean;
  onSelect?: (start, end) => void;
  onReset?: () => void;
};

export const DateRangePicker = ({ showCustomDates, onSelect, onReset }: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date>();
  const { t } = useTranslation();

  const onChange = (dates: Date[] | any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      {showCustomDates && (
        <Container>
          <Row className="align-items-center justify-content-between mb-3">
            <Col lg={6} xs={12}>
              <p className="text-dark">
                <strong>{t("filter.orders.start_at")}</strong>
              </p>
              <div className="bg-primary-light rounded p-3 text-center w-100">
                {getFormattedDate(startDate.toDateString())}
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <p className="text-dark">
                <strong>{t("filter.orders.end_at")}</strong>
              </p>
              <div className="bg-primary-light rounded p-3 text-center w-100">
                {getFormattedDate(endDate?.toDateString() || startDate.toDateString())}
              </div>
            </Col>
          </Row>
        </Container>
      )}
      <div>
        <DatePicker
          className={`${styles.customeDate}`}
          calendarClassName="customeDate"
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
        />
      </div>

      <Container className="d-flex justify-content-end">
        {onReset && (
          <Button className="btn btn-close-white mx-3 px-3" onClick={() => onReset()}>
            Reset
          </Button>
        )}

        {onSelect && <Button onClick={() => onSelect(startDate, endDate)}>Select</Button>}
      </Container>
    </>
  );
};
