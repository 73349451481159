export enum ORDER_EVENTS {
  NEW_ORDER_CREATED = "NEW_ORDER_CREATED",
  ORDER_UPDATED = "ORDER_UPDATED",
}

export enum SUBSCRIPTION_EVENTS {
  NEW_SUBSCRIPTION_CREATED = "NEW_SUBSCRIPTION_CREATED",
  SUBSCRIPTION_UPDATED = "SUBSCRIPTION_UPDATED",
}

export enum CHANNEL_EVENTS {
  ORDERS = "Orders",
  SUBSCRIPTIONS = "Subscriptions",
}

export enum EVENTS_LISTENER {
  CREATED = ".created",
  UPDATED = ".updated",
}
