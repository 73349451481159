import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules (Markting Module)
const ListCustomersFeedbackPage = React.lazy(() => import(`../pages/dashboard/customers-feedback/list`));
// It's corresponding array routes
export const CustomersFeedbackRoutes: JSX.Element[] = [
  <Route
    exact
    key="list-customers-feedback"
    path={DashboardRouteUtil.getListCustomersFeedbackPath()}
    component={() => withAuth(withSuspense(ListCustomersFeedbackPage))}
  />
];
