import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withAuth } from "./core/helpers/withAuth";
import { withPermission } from "./core/helpers/withPermission";
import { withSuspense } from "./core/helpers/withSuspense";
import { DashboardRouteUtil } from "./core/routes/dashboard.route";
import MarketingModule from "./pages/dashboard/marketing";
import {
  branchesRoutes,
  MarketingRoutes,
  CustomersRoutes,
  PermissionsRoutes,
  SellerBalance,
  CitiesCountiesRoutes,
  authRoutes,
  notificationRoutes,
  annoucemenetRoutes,
  CustomersFeedbackRoutes,
  bannersRoutes,
  AuditsRoutes
} from "./routes/index.routing";
import { SettingsRoutes } from "./routes/settings.routing";
import { SubscriptionsRoutes } from "./routes/subscription.routing";

const OrdersPage = React.lazy(() => import("./pages/dashboard/order/list"));

const OrderEditPage = React.lazy(() => import("./pages/dashboard/order/edit"));

const DashboardShowPage = React.lazy(() => import("./pages/dashboard/dashboard/show"));

const SellersPage = React.lazy(() => import("./pages/dashboard/seller/list"));

const FeaturedSellersPage = React.lazy(() => import("./pages/dashboard/seller/feature-seller"));

const SellerSectionsPage = React.lazy(() => import("./pages/dashboard/seller/section/list"));

const SellerOffersPage = React.lazy(() => import("./pages/dashboard/seller/offers/list"));

const CreateSellerPage = React.lazy(() => import("./pages/dashboard/seller/create"));

const EditSellerPage = React.lazy(() => import("./pages/dashboard/seller/edit"));

const WorkingHoursPage = React.lazy(() => import("./pages/dashboard/seller/working-hours"));

const CategoriesListPage = React.lazy(() => import("./pages/dashboard/category/list"));

const CreateItemPage = React.lazy(() => import("./pages/dashboard/seller/items/create"));

const EditItemPage = React.lazy(() => import("./pages/dashboard/seller/items/edit"));

const ItemsListPage = React.lazy(() => import("./pages/dashboard/seller/items/list"));

const CreateVoucherPage = React.lazy(() => import("./pages/dashboard/voucher/create"));

const NotificationPage = React.lazy(() => import("./pages/dashboard/notification/list"));

const CreateNotificationPage = React.lazy(() => import("./pages/dashboard/notification/create"));

const EditNotificationPage = React.lazy(() => import("./pages/dashboard/notification/edit"));

const AnnouncementPage = React.lazy(() => import("./pages/dashboard/announcement/list"));

const CreateAnnouncementPage = React.lazy(() => import("./pages/dashboard/announcement/create"));

const EditAnnouncementPage = React.lazy(() => import("./pages/dashboard/announcement/edit"));

const PaymentTransactions = React.lazy(() => import("./pages/dashboard/payment-transaction/list"));

const CouponLogs = React.lazy(() => import("./pages/dashboard/logs/coupon/list"));

function AppRouting() {
  const dashboardRoutes: JSX.Element[] = [
    <Route
      exact
      key="dashboard"
      path={DashboardRouteUtil.getDashboardPath()}
      component={() =>
        withAuth(withPermission("can_dashboard_view", withSuspense(DashboardShowPage)))
      }
    />,
    <Route
      exact
      key="orders"
      path={DashboardRouteUtil.getOrdersListPath()}
      component={() => withAuth(withSuspense(OrdersPage))}
    />,
    <Route
      exact
      key="order-edit"
      path={DashboardRouteUtil.getOrderEditPath()}
      component={() => withAuth(withSuspense(OrderEditPage))}
    />,
    <Route
      exact
      key="sellers"
      path={DashboardRouteUtil.getSellersListPath()}
      component={() => withAuth(withSuspense(SellersPage))}
    />,
    <Route
      exact
      key="sellers"
      path={DashboardRouteUtil.getFeaturedSellersListPath()}
      component={() => withAuth(withSuspense(FeaturedSellersPage))}
    />,
    <Route
      exact
      key="seller-create"
      path={DashboardRouteUtil.getSellerCreatePath()}
      component={() => withAuth(withSuspense(CreateSellerPage))}
    />,
    <Route
      exact
      key="seller-edit"
      path={DashboardRouteUtil.getSellerEditPath()}
      component={() => withAuth(withSuspense(EditSellerPage))}
    />,
    <Route
      exact
      key="seller-working-hours"
      path={DashboardRouteUtil.getSellerWorkingHoursPath()}
      component={() => withAuth(withSuspense(WorkingHoursPage))}
    />,
    <Route
      exact
      key="seller-sections"
      path={DashboardRouteUtil.getSellerSectionPath()}
      component={() => withAuth(withSuspense(SellerSectionsPage))}
    />,
    <Route
      exact
      key="seller-sections"
      path={DashboardRouteUtil.getSellerOfferPath()}
      component={() => withAuth(withSuspense(SellerOffersPage))}
    />,
    <Route
      exact
      key="seller-items-edit"
      path={DashboardRouteUtil.getSellerItemsEditPath()}
      component={() => withAuth(withSuspense(EditItemPage))}
    />,
    <Route
      exact
      key="seller-items-edit-2"
      path={DashboardRouteUtil.getSellerBranchItemsEditPath()}
      component={() => withAuth(withSuspense(EditItemPage))}
    />,
    <Route
      exact
      key="seller-items-create"
      path={DashboardRouteUtil.getSellerItemsCreatePath()}
      component={() => withAuth(withSuspense(CreateItemPage))}
    />,
    <Route
      exact
      key="seller-items-list"
      path={DashboardRouteUtil.getSellerItemsListPath()}
      component={() => withAuth(withSuspense(ItemsListPage))}
    />,
    <Route
      exact
      key="Categories"
      path={DashboardRouteUtil.getCategoriesPath()}
      component={() => withAuth(withSuspense(CategoriesListPage))}
    />,
    <Route
      exact
      key="offers"
      path={DashboardRouteUtil.getMarketingModulePath()}
      component={() => withAuth(withSuspense(MarketingModule))}
    />,
    <Route
      exact
      key="voucher-create"
      path={DashboardRouteUtil.getOffersCreatePath()}
      component={() => withAuth(withSuspense(CreateVoucherPage))}
    />,
    <Route
      exact
      key="announcements"
      path={DashboardRouteUtil.getAnnouncementPath()}
      component={() => withAuth(withSuspense(AnnouncementPage))}
    />,
    <Route
      exact
      key="create-announcement"
      path={DashboardRouteUtil.createAnnouncement()}
      component={() => withAuth(withSuspense(CreateAnnouncementPage))}
    />,
    <Route
      exact
      key="edit-announcement"
      path={DashboardRouteUtil.editAnnouncement()}
      component={() => withAuth(withSuspense(EditAnnouncementPage))}
    />,
    <Route
      exact
      key="notifications"
      path={DashboardRouteUtil.getNotificationPath()}
      component={() => withAuth(withSuspense(NotificationPage))}
    />,
    <Route
      exact
      key="create-notification"
      path={DashboardRouteUtil.createNotification()}
      component={() => withAuth(withSuspense(CreateNotificationPage))}
    />,
    <Route
      exact
      key="edit-notification"
      path={DashboardRouteUtil.editNotification()}
      component={() => withAuth(withSuspense(EditNotificationPage))}
    />,
    <Route
      exact
      key="payment-transaction"
      path={DashboardRouteUtil.getPaymentTransaction()}
      component={() => withAuth(withSuspense(PaymentTransactions))}
    />,
    <Route
      exact
      key="payment-transaction-details"
      path={DashboardRouteUtil.getPaymentTransactionDetailsPath()}
      component={() => withAuth(withSuspense(PaymentTransactions))}
    />,
    <Route
      exact
      key="reports/coupon"
      path={DashboardRouteUtil.getReportsCoupon()}
      component={() => withAuth(withSuspense(CouponLogs))}
    />,
  ];

  const commonRoutes = [
    <Redirect path="*" to={DashboardRouteUtil.getDashboardPath()} exact key="redirect" />,
  ];

  const finalRoutes = [
    ...authRoutes,
    ...AuditsRoutes,
    ...dashboardRoutes,
    ...branchesRoutes,
    ...MarketingRoutes,
    ...PermissionsRoutes,
    ...SellerBalance,
    ...CustomersRoutes,
    ...CustomersFeedbackRoutes,
    ...bannersRoutes,
    ...CitiesCountiesRoutes,
    ...SubscriptionsRoutes,
    ...SettingsRoutes,
    ...commonRoutes,
    ...notificationRoutes,
    ...annoucemenetRoutes,
  ];

  return (
    <React.Suspense fallback={<></>}>
      <Switch>{finalRoutes}</Switch>
    </React.Suspense>
  );
}

export default AppRouting;
