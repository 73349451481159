import React, { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';

import styles from './styles.module.scss';

/***
 * Confirm Message
 * @param id
 * @param placement
 * @param HTMLContent
 * @param TooltipContent
 * @param extraCustomTooltipClasses
 * @return {JSX.Element}
 */

function Example({
  id = '',
  HTMLContent = <>Hello Tooltip</>,
  TooltipContent = '',
  extraCustomTooltipClasses = ''
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <div style={{ cursor: 'pointer' }} ref={target} onClick={() => setShow(!show)}>
        {HTMLContent}
      </div>
      <Overlay target={target.current} show={show} placement="bottom">
        <div className={`${extraCustomTooltipClasses} py-3 px-2 ${styles.feedback__tooltip}`}>
          <div
            className={`${styles.inner__text} scrollbar px-1`}>
            {TooltipContent}
          </div>
        </div>
      </Overlay>
    </>
  );
}

export default Example;