import { Variant } from "../../../core/enums/variants.enum";
import {
  OppositeSideMargin,
  OppositeSidePosition,
  SidePosition,
} from "../../../core/helpers/style.helper";
import { Button } from "react-bootstrap";

const Toggle = ({ isActive = false, handleOnChange, disabled }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <span className={`text-${Variant.SUCCESS}`} style={OppositeSideMargin("20px")} />
        <Button
          disabled={disabled}
          variant={isActive ? Variant.SUCCESS : Variant.DANGER}
          className="position-relative"
          style={{
            width: 37,
            height: 19,
            borderRadius: "50px",
          }}
          onClick={handleOnChange}
        >
          <div
            className="position-absolute bg-white rounded-circle"
            style={{
              height: 11,
              width: 11,
              bottom: 3,
              ...(isActive ? OppositeSidePosition("4px") : SidePosition("4px")),
            }}
          />
        </Button>
      </div>
    </>
  );
};
export default Toggle;
